<template>
  <b-card
    border-variant="success"
    title="Cess"
  >
    <b-card-text>
      Pay for cess
    </b-card-text>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.initiate-cess-payment
      variant="outline-success"
    >
      <feather-icon
        icon="DollarSignIcon"
        class="mr-50"
      />
      <span class="align-middle">Pay</span>
    </b-button>
    <!-- modal -->
    <b-overlay
      :show="showWaitingPayment"
      rounded="sm"
    >
      <b-modal
        id="initiate-cess-payment"
        ref="my-modal"
        title="Pay for Cess"
        ok-title="Pay"
        cancel-variant="outline-secondary"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleSubmit"
      >
        <validation-observer ref="simpleRules">
          <statistic-card-horizontal
            :class="{ hide: !showOtherDetails }"
            icon="DollarSignIcon"
            color="success"
            :statistic="'Amount to Pay - KES ' + amountToPay"
          />
          <form
            ref="form"
            @submit.stop.prevent="handleSubmit"
          >
            <b-form-group
              label="Cess Service"
              label-for="service"
            >
              <validation-provider
                #default="{ errors }"
                name="Cess Service"
                rules="required"
              >
                <v-select
                  id="service"
                  v-model="service"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="serviceOptions"
                  :@change="calculateServicePrice()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Collection Center"
              label-for="collectionCenter"
            >
              <validation-provider
                #default="{ errors }"
                name="Collection Center"
                rules="required"
              >
                <v-select
                  id="collectionCenter"
                  v-model="collectionCenter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="collectionCenterOptions"
                  :@change="calculateServicePrice()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div
              :class="{ hide: !showOtherDetails }"
            >
              <b-form-group
                label="Reg No"
                label-for="regNo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Reg No"
                  rules="required"
                >
                  <b-form-input
                    id="regNo"
                    v-model="regNo"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Reg No"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Phone No -->
              <b-form-group
                label="Phone No"
                label-for="phoneNo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone No"
                  rules="required"
                >
                  <b-form-input
                    id="phoneNo"
                    v-model="phoneNo"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Phone No"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </form>
        </validation-observer>
      </b-modal>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BFormGroup, BFormInput, BModal, VBModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import appUtils from '@/auth/jwt/useJwt'
import { required } from '@validations'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    StatisticCardHorizontal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      showWaitingPayment: false,
      regNo: null,
      phoneNo: null,
      submittedNames: [],
      collectionCenter: null,
      collectionCenterOptions: [],
      service: null,
      serviceOptions: [],
      required,
      connection: null,
      showOtherDetails: false,
      amountToPay: 0,
    }
  },
  mounted() {
    this.fetchInitialData()
  },
  methods: {
    calculateServicePrice() {
      if (this.service != null && this.collectionCenter != null) {
        appUtils.fetchServicePrice({
          serviceId: this.service.id,
          type: 'cess',
          collectionCenter: this.collectionCenter.id,
        }).then(response => {
          if (response.data.data > 0) {
            this.showOtherDetails = true
            this.amountToPay = response.data.data
          } else {
            this.$swal({
              title: 'Error!',
              text: 'An error has occurred, please contact the county for assistance',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
      }
    },
    fetchInitialData() {
      appUtils.fetchCollectionCenters().then(response => {
        this.collectionCenterOptions = response.data.data
      })
      appUtils.fetchCessServices().then(response => {
        this.serviceOptions = response.data.data
      })
    },
    resetModal() {
      this.regNo = null
      this.collectionCenter = null
      this.service = null
      this.phoneNo = null
    },
    handleSubmit(ev) {
      // Prevent modal from closing
      ev.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // Trigger submit handler
          // eslint-disable-next-line
          appUtils.payForCess({
            regNo: this.regNo,
            phoneNo: this.phoneNo,
            service: this.service.id,
            collectionCenter: this.collectionCenter.id,
          })
            .then(response => {
              if (response.data.status === '00') {
                this.$swal({
                  title: 'Success!',
                  text: response.data.message,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else {
                this.$swal({
                  title: 'Error!',
                  text: response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  .hide {
    display: none !important;
  }
</style>
