<template>
  <b-row class="match-height">
    <b-col
      lg="3"
      sm="6"
    >
      <daily-parking />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <cess-form />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <b-card
        border-variant="danger"
        title="Single Business Permit"
      >
        <b-card-text>
          Pay for your SBP invoices
        </b-card-text>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
        >
          <b-link
            class="alert-link"
            :to="{ name: 'services-sbp'}"
          >
            <span class="text-danger">Get Service</span>
          </b-link>
        </b-button>
      </b-card>
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <b-card
        border-variant="secondary"
        title="Property Rates"
      >

        <b-card-text>
          Pay for your property rates
        </b-card-text>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
        >
          Get Service
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import DailyParking from '../parking/daily-parking/DailyParking.vue'
import CessForm from '../cess/CessForm.vue'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BLink,
    DailyParking,
    CessForm,
  },
  directives: {
    Ripple,
  },
}
</script>
